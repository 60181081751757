/* Création d'une classe 'Utilisateurs' */

// Publication du module
export default class user_class {
    constructor(name, first_name, email, password, role, active) {
        this.first_name = first_name;
        this.name = name;
        this.email = email;
        this.password = password;
        this.role = role;
        this.active = active;
    }
}
