<template>
    
    <!-- Structure -->
    <v-container fluid>
    
        <v-row align="center" justify="center">
            <v-col :cols="form_cols">
            
                <v-card class="elevation-12">
                    <v-toolbar color="green darken-3" dark flat>
                        <v-toolbar-title>TRIEVES.GAMES</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-icon>mdi-code-tags</v-icon>
                    </v-toolbar>
                    <v-card-text>
                        <span>Afin de valider votre compte, merci de définir un mot de passe sécurisé.</span>
                        <v-form
                            class="mt-10"
                            lazy-validation
                            name="validation_form"
                            ref="validation_form"
                            v-model="valid"
                            @submit="validate">
                               <v-text-field
                                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    label="Mot de passe"
                                    name="password"
                                    placeholder="Veuillez choisir un mot de passe"
                                    prepend-icon="mdi-lock"
                                    required
                                    :rules="password_rules"
                                    :type="show_password ? 'text' : 'password'"
                                    v-model="user.password"
                                    @click:append="show_password = !show_password"
                                    @click="clear_alert_display('error')"
                                    @keydown.enter="validate">
                                </v-text-field>
                                <v-text-field
                                    :append-icon="show_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                                    label="Confirmation"
                                    name="confirm"
                                    placeholder="Veuillez confirmer votre mot de passe"
                                    prepend-icon="mdi-lock"
                                    :rules="confirm_rules"
                                    required
                                    :type="show_confirm ? 'text' : 'password'"
                                    @click:append="show_confirm = !show_confirm"
                                    @click="clear_alert_display('error')"
                                    @keydown.enter="validate">
                                </v-text-field>
                        </v-form>
                        <v-alert 
                            border="left" 
                            dense
                            dismissible
                            text
                            transition="fade-transition"
                            type="error"
                            v-model="error_alert">
                                {{ error }}
                        </v-alert>
                        <v-alert 
                            border="left" 
                            dense
                            dismissible
                            text
                            transition="fade-transition"
                            type="success"
                            v-model="message_alert">
                                {{ message }}
                        </v-alert>
                        <v-alert 
                            border="left" 
                            dense
                            dismissible
                            text
                            transition="fade-transition"
                            type="warning"
                            v-model="warning_alert">
                                {{ warning }}
                        </v-alert>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue"
                            dark
                            :disabled="!valid" 
                            text
                            @click="validate">
                            Enregistrer
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import user_class from "../models/user.class";

var error_alert_timer;
var message_alert_timer;
var warning_alert_timer;

export default {
    computed: {
        form_cols() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 10
                case 'sm': return 8
                case 'md': return 6
                case 'lg': return 4
                case 'xl': return 4
                default: return 4
            }
        },
        token() {
            return this.$route.query.token;
        }
    },
    created() {
    },
    data() {
        return {
            confirm_rules: [
                v => (!!v && v) === this.user.password || 'Validation : le mot de passe ne correspond pas.'
            ],
            error: '',
            error_alert: false,
            password_rules: [
                v => !!v || 'Mot de passe requis',
                v => (v && v.length >= 6) || 'Validation : le mot de passe n\'est pas assez sécurisé',
            ],
            message: '',
            message_alert: false,
            show_confirm: false,
            show_password: false,
            user: new user_class(),
            valid: true,
            warning: '',
            warning_alert: false
        };
      },
      methods: {
         alert_display(alert_type){
            switch(alert_type){
                case 'error' :
                    this.error_alert = true;
                    error_alert_timer = setTimeout(() => (this.error_alert = false), 5000);
                    break;
                case 'warning' :
                    this.warning_alert = true;
                    warning_alert_timer = setTimeout(() => (this.warning_alert = false), 5000);
                    break;
                case 'message' :
                    this.message_alert = true;
                    message_alert_timer = setTimeout(() => (this.message_alert = false), 5000);
                    break;
            }
        },
        clear_alert_display(alert_type){
            switch(alert_type){
                case 'error' :
                    this.error_alert = false;
                    clearTimeout(error_alert_timer);
                    break;
                case 'warning' :
                    this.warning_alert = false;
                    clearTimeout(warning_alert_timer);
                    break;
                case 'message' :
                    this.message_alert = false;
                    clearTimeout(message_alert_timer);
                    break;
            }
        },
        validate() {
            this.clear_alert_display('error');
            this.clear_alert_display('warning');
            this.clear_alert_display('message');
            if (this.valid && this.user.password) {
                this.$store.dispatch("auth/validation", {password: this.user.password, token: this.token}).then((response) => {
                    if (!response.data.message) {
                        setTimeout(() => (
                            this.clear_alert_display('error'),
                            this.clear_alert_display('message'),
                            this.clear_alert_display('warning'),
                            this.$router.push("/login")
                        ), 5000);
                    } else {
                        this.message = response.data.message;
                        this.alert_display('message');
                        setTimeout(() => (
                            this.clear_alert_display('error'),
                            this.clear_alert_display('message'),
                            this.clear_alert_display('warning'),
                            this.$router.push("/login")
                        ), 5000);
                    }
                },
                error => {
                    if(error.response.data.error){
                        this.error = 
                            (error.response && error.response.data.error) ||
                            error.message ||
                            error.toString();
                        this.alert_display('error');
                    } else {
                        this.warning = 
                            (error.response && error.response.data.warning) ||
                            error.message ||
                            error.toString();
                        this.alert_display('warning');
                    }
                });
            } else {
                this.error = 'Validation : veuillez compléter correctement tous les champs du formulaire';
                this.alert_display('error');
            }
        },
        validate_field () {
            console.log("OK");
            this.$refs.validation_form.validate()
        }
    },
    name: "validation",
    watch: {
        user: 'validate_field'
    }
};

</script>
